<script setup lang="ts">
defineSlice({
  name: { label: 'Bento Slice Wireframe', group: 'Bento', layouts: ['VoixMarketing'] },
  description: '',
  preview: 'SlicesBentoSliceWireframe.vue',
  fields: {

  },
  templates: [{
    label: 'Main Example',
    fields: {

    },
  }],
})

const activated = inject('activated')
</script>

<template>
  <div class="w-full h-full p-8">
    <DemosSliceOutlinesShapes :play="activated" class="duration-1000 delay-500" :class="{ 'opacity-50': !activated }" />
  </div>
</template>
